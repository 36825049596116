<template>
  <i18nLink
    v-if="attributes?.slug"
    :to="{ name: 'events-slug', params: { slug: attributes?.slug }, query: query }"
    :locale="i18nCode"
    :class="$style.root"
  >
    <slot />
  </i18nLink>
</template>

<script lang="ts" setup>

const props = defineProps({
  data: Object
})

const localizedData = baseStrapiGetLocalizedData(props.data)
const route = useRoute()

const attributes = baseStrapiGetAttributes(localizedData)
const i18nCode = baseI18nGetCodeForIso(attributes?.locale)

const query = computed(() => {return { ...route?.query, year: dayJs(attributes?.sortKeyAsc)?.format('YYYY'), month: dayJs(attributes?.sortKeyAsc)?.format('M') }})
</script>

<style module>
.root {
  composes: reset-link from global;
}

.root:hover [class*='title'],
.root:global(.router-link-exact-active) [class*='title'] {
  text-decoration-line: underline;
}
</style>
