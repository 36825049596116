<template>
  <div :class="$style.root">
    <div :class="$style.header">
      <span :class="$style.title">{{ attributes?.title }}</span>&nbsp;<template v-for="(tag, index) in tags" :key="index"><span :class="$style.tag">{{ tag?.trim() }}</span>&nbsp;</template>
    </div>

    <ul
      v-if="attributes?.dateTimeRanges?.length"
      :class="$style.dateRanges"
    >
      <div
        v-if="dateTimeRange"
        :class="$style.dateRange"
      >
        <CoreDateTimeRangeComponent
          :dateStart="dateTimeRange?.dateTimeStart"
          :dateEnd="dateTimeRange?.dateTimeEnd"
        />&nbsp;<CoreTimeLabelComponent
          :dateStart="dateTimeRange?.dateTimeStart"
          :dateEnd="dateTimeRange?.dateTimeEnd"
        />
      </div>
    </ul>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  data: Object,
  showTags: {
    type: Boolean,
    default: true,
  }
})

const localizedData = baseStrapiGetLocalizedData(props.data)

const attributes = baseStrapiGetAttributes(localizedData)

const dateTimeRange = computed(() => {
  if (attributes?.dateTimeRanges?.length === 1) {
    return { ...attributes?.dateTimeRanges[0] }
  } else {
    return {
      dateTimeStart: attributes?.dateTimeRanges[0]?.dateTimeStart,
      dateTimeEnd: attributes?.dateTimeRanges[attributes?.dateTimeRanges?.length - 1]?.dateTimeEnd,
    }
  }
})

const tags = attributes?.eventCategories?.data?.map(i => i?.attributes?.label)?.filter(Boolean)
</script>

<style module>
.root {
  overflow: hidden;
}

.dateRanges {
  composes: reset-list from global;
  composes: font-helvetica font-size-small from global;

  display: flex;
  flex-wrap: wrap;
}

.dateRange {
  display: inline-flex;
}

.dateRange:not(:last-of-type):after {
  content: ', ';
  white-space: pre-wrap;
}

.header {
  composes: font-size-default from global;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  line-height: 1;
  padding-top: .2em;

  max-width: calc(100% - var(--padding--element--left) * 2);
}

.title {

}

.categories {
  composes: font-size-small font-helvetica from global;
}

.tag {
  composes: font-size-small font-helvetica label-inverted from global;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
